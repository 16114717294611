import { checkInputValue } from "./utils/check-input-value";
import { sendHttpRequest } from "./utils/send-http-request";
import { getCsrftoken } from "./utils/get-csrftoken";
import { showPopUpWindow } from "./pop-up-window";
import { hideLoader, showFormLoader } from "./utils/form-loader";
import { createBodyForRequest } from "./utils/create-body-for-request";
import {
    sendCalculatorWasTouched,
    sendScrollToSubmitButton,
    sendSubmitButtonWasClicked,
    sendWasOnPageMoreThan15Sec
} from "./utils/metrics";
import { isInViewport } from "./utils/is-in-viewport";
import { disableFormSubmission, formCanSend } from "./utils/disable-form-submission";

export function loadSimpleCalculator(): void {
    const simpleCalculator: HTMLElement = document.querySelector('#simple-calculator');
    const inputField: HTMLInputElement = simpleCalculator.querySelector('#input-field');
    const submitButton: HTMLElement = simpleCalculator.querySelector('#submit-button');
    const overpaymentWrapper: HTMLElement = simpleCalculator.querySelector('#overpayment-wrapper');
    const paymentWrapper: HTMLElement = simpleCalculator.querySelector('#payment-wrapper');
    const savingsWrapper: HTMLElement = simpleCalculator.querySelector('#savings-wrapper');

    const maxValueOfCalculator: number = 20000;

    const numberOfPeriods = 12,
        monthlyCommissionPercentage = 0.05, // 5%
        loanRatePercentage = 0.0001; // 0.01%

    function setPlaceholderForInputField(value: string): void {
        inputField.setAttribute('placeholder', value);
    }

    function imitateInput() {
        inputField.dispatchEvent(new Event('input'));
    }

    function savings(creditSum: number): number {
        let S = creditSum;
        let R = loanRatePercentage;
        let Pnew = payment(creditSum);
        let P = S * 1.12 * R + S * 1.12 / 24;
        return P - Pnew;
    }

    function payment(creditSum: number): number {
        let S = creditSum;
        let Rnew = 0.5;
        return S * 1.12 * Rnew + S * 1.12 / 24;
    }

    function overpayment(creditSum: number): number {
        let S = creditSum;
        let N = numberOfPeriods;
        let Pnew = payment(creditSum);
        return (Pnew - S / N) / 30;
    }

    function handleInput(e: any): void {
        e.target.value = e.target.value.replace(/[^\d]/g, '');
        e.target.value = checkInputValue(e.target.value, maxValueOfCalculator);
        let sum: number = !e.target.value ? inputField.getAttribute('placeholder') : e.target.value;
        overpaymentWrapper.innerHTML = overpayment(sum).toFixed(2);
        paymentWrapper.innerHTML = payment(sum).toFixed(2);
        savingsWrapper.innerHTML = savings(sum).toFixed(2);
    }

    function submit(e: Event): void {
        e.preventDefault();

        if (formCanSend) {
            disableFormSubmission();
            showFormLoader('#submit-button');

            const body = createBodyForRequest('slackCallMe', `${inputField.value || inputField.getAttribute('placeholder')}`, false);
            const csrftoken = getCsrftoken();
            sendHttpRequest('POST', 'https://welcome.sfactor.com.ua/api/button-actions/', body, csrftoken)
                .then(() => {
                    hideLoader('#submit-button');
                    showPopUpWindow({ requestIsSuccessful: true });
                })
                .catch((error) => {
                    hideLoader('#submit-button');
                    showPopUpWindow({ requestIsSuccessful: false });
                    console.error(error);
                });
        } else {
            showPopUpWindow({ requestIsSuccessful: true });
        }
    }

    setPlaceholderForInputField(`${maxValueOfCalculator}`);
    submitButton.addEventListener('click', submit);
    inputField.addEventListener('input', handleInput);
    imitateInput();

    // analytics
    function checkIfSubmitButtonIsInViewport() {
        if (isInViewport(submitButton)) {
            sendScrollToSubmitButton();
            window.removeEventListener('scroll', checkIfSubmitButtonIsInViewport);
        }
    }

    function checkIfSubmitButtonWasClicked() {
        sendSubmitButtonWasClicked();
        submitButton.removeEventListener('click', checkIfSubmitButtonWasClicked);
    }

    function checkIfCalculatorWasTouched() {
        sendCalculatorWasTouched();
        inputField.removeEventListener('focus', checkIfCalculatorWasTouched);
    }

    sendWasOnPageMoreThan15Sec();
    window.addEventListener('scroll', checkIfSubmitButtonIsInViewport);
    inputField.addEventListener('focus', checkIfCalculatorWasTouched);
    submitButton.addEventListener('click', checkIfSubmitButtonWasClicked);
}