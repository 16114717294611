export function loadPaperPlaneAnimation() {
    let paperPlane: HTMLElement = document.querySelector('#paper-plane');
    let offsetStart: number = paperPlane.dataset.offsetStart ? +paperPlane.dataset.offsetStart : 0;
    let offsetEnd: number = paperPlane.dataset.offsetEnd ? +paperPlane.dataset.offsetEnd : 0;

    function checkScroll() {
        if (window.innerHeight > paperPlane.getBoundingClientRect().y && window.innerHeight < paperPlane.getBoundingClientRect().bottom) {
            offsetStart = window.pageYOffset;
            window.removeEventListener('scroll', checkScroll);
        } else if (window.innerHeight > paperPlane.getBoundingClientRect().bottom) {
            const test = window.innerHeight - paperPlane.getBoundingClientRect().bottom;
            offsetStart = window.pageYOffset - test;
            window.removeEventListener('scroll', checkScroll);
        }
    }

    window.addEventListener('scroll', checkScroll);

    window.addEventListener('scroll', () => {
        paperPlane = document.querySelector('#paper-plane');
        offsetEnd = paperPlane.dataset.offsetEnd ? +paperPlane.dataset.offsetEnd : 0;
        document.body.style.setProperty(
            '--paper-plane-scroll',
            `${( window.pageYOffset - offsetStart ) / ( document.body.offsetHeight - offsetStart - offsetEnd - window.innerHeight )}`
        );
    }, false);

    window.addEventListener('resize', () => {
        window.addEventListener('scroll', checkScroll);
    });
}