export function loadBalloonAnimation() {
    let balloon: HTMLElement = document.querySelector('#balloon');

    let offsetStart: number = balloon.dataset.offsetStart ? +balloon.dataset.offsetStart : 0;
    let offsetEnd: number = balloon.dataset.offsetEnd ? +balloon.dataset.offsetEnd : 0;

    window.addEventListener('scroll', () => {
        balloon = document.querySelector('#balloon');
        offsetStart = balloon.dataset.offsetStart ? +balloon.dataset.offsetStart : 0;
        offsetEnd = balloon.dataset.offsetEnd ? +balloon.dataset.offsetEnd : 0;
        document.documentElement.style.setProperty(
            '--balloon-scroll',
            `${( window.pageYOffset - offsetStart ) / ( document.body.offsetHeight - offsetStart - offsetEnd - window.innerHeight )}`
        );
    }, false);
}
