var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                   <li class=\"list-item bg-lightgray rounded-2 py-1 mx-1 mb-2 mb-xxl-0\">\n                       <img src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"link") : depth0), depth0))
    + "\" alt=\"\" class=\"list-image\">\n                   </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"bg-black\">\n    <div class=\"container text-lightgray text-center pt-4 pb-1 d-flex flex-column align-items-center\">\n        <img class=\"logo mt-4 pt-2\" src=\"f83bfde6c79c6518f5f6.png\" alt=\"\">\n\n        <div class=\"text-center mt-3 pt-1 mb-4 pb-1 fs-7 order-2 order-xxl-1 mt-xxl-4 pt-xxl-2 mb-xxl-3 pb-xxl-0\">\n            <p class=\"mb-0\">\n                © 2020. Всі права захищені | Created D3D.studio\n            </p>\n            <p class=\"mb-0\">\n                ТОВ «Сучасний факторинг»- Свідоцтво №13102578 ФК №270 про реєстрацію фінансової установи, видане Нацкомфінпослуг 20.01.2011 р.\n            </p>\n        </div>\n\n       <div class=\"order-1 d-flex flex-column mt-4 pt-2\">\n           <ul class=\"list d-flex justify-content-center flex-wrap order-2 order-xxl-1\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"images") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":15},"end":{"line":20,"column":24}}})) != null ? stack1 : "")
    + "           </ul>\n           <p class=\"fs-7 col-12 col-sm-8 mx-auto mb-3 order-1\">\n               Можливість сплачувати он-лайн, в терміналах самообслуговування або в відділенні банку\n           </p>\n       </div>\n    </div>\n</section>\n";
},"useData":true});