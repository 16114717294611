var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    return "                    <button id=\"personal-account-button\" class=\"btn btn-success text-white btn-fs\">\n                        Особистий кабінет\n                    </button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"text-white fs-xl-6 mb-5 d-sm-none\">\n                        <p class=\"mb-0\">\n                            «Сучасний Факторинг»\n                        </p>\n                        <p class=\"mb-0\">\n                            — простий крок до мрії!\n                        </p>\n                    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"fw-bold text-center mb-4 pb-2 d-sm-none d-lg-block ms-lg-5 ps-lg-5\">\n                        <p class=\"mb-0 text-success fs-1 fs-xl-3\">\n                            2 місяці\n                        </p>\n                        <p class=\"mb-0 text-primary fs-xl-6\">\n                            пільговий період\n                        </p>\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"min-vh-100 d-flex align-items-center\">\n    <div class=\"container text-light py-5\">\n        <div class=\"row justify-content-between align-items-center\">\n            <div class=\"col col-auto pe-0\">\n                <a href=\"#\">\n                    <img class=\"logo\" src=\"f83bfde6c79c6518f5f6.png\" alt=\"\">\n                </a>\n            </div>\n            <div class=\"col col-auto order-3 d-none d-sm-block mt-3 mt-md-0 d-xl-none col-12 col-md-auto pe-0\">\n                <div class=\"text-white fs-xl-6\">\n                    <p class=\"mb-0\">\n                        «Сучасний Факторинг»\n                    </p>\n                    <p class=\"mb-0\">\n                        — простий крок до мрії!\n                    </p>\n                </div>\n            </div>\n            <div class=\"col col-auto order-md-3 d-flex align-items-center ps-0\">\n                <div class=\"text-white d-none d-xl-block me-5 fs-xl-6\">\n                    <p class=\"mb-0\">\n                        «Сучасний Факторинг»\n                    </p>\n                    <p class=\"mb-0\">\n                        — простий крок до мрії!\n                    </p>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"simpleCalculator") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":33,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div\n            class=\"row mt-4 mt-sm-5 pt-md-5 pt-lg-0 flex-column-reverse flex-sm-row flex-grow-1 align-items-center\n                    pt-xl-3\"\n        >\n            <div class=\"col mt-4 d-flex flex-column justify-content-center\">\n                <p class=\"mb-0 text-primary fw-bold fs-2 fs-lg-2 fs-xl-2 lh-sm\">\n                    ВІТАЄМО,\n                </p>\n                <p class=\"text-success fs-3 fs-lg-3 fs-xl-3 fw-bold col-5 mb-3 mb-xl-4 lh-sm\">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"fullName") || (depth0 != null ? lookupProperty(depth0,"fullName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fullName","hash":{},"data":data,"loc":{"start":{"line":46,"column":20},"end":{"line":46,"column":34}}}) : helper)))
    + "\n                </p>\n\n                <p class=\"text-white mb-1 mb-xl-3 fs-xl-6\">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":50,"column":20},"end":{"line":50,"column":30}}}) : helper)))
    + "\n                </p>\n                <p class=\"text-success fs-1 fs-xl-2 fw-bold mb-2 mb-xl-3\">\n                    до "
    + alias4(((helper = (helper = lookupProperty(helpers,"sum") || (depth0 != null ? lookupProperty(depth0,"sum") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sum","hash":{},"data":data,"loc":{"start":{"line":53,"column":23},"end":{"line":53,"column":32}}}) : helper)))
    + " грн\n                </p>\n                <p class=\"text-white fs-xl-6\">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"text2") || (depth0 != null ? lookupProperty(depth0,"text2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text2","hash":{},"data":data,"loc":{"start":{"line":56,"column":20},"end":{"line":56,"column":31}}}) : helper)))
    + "\n                </p>\n            </div>\n\n            <div\n                    class=\"col col-sm-4 col-md-5 col-lg-6 d-flex flex-column align-items-center justify-content-end\n                    position-relative my-3 my-sm-0\"\n            >\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"simpleCalculator") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":64,"column":16},"end":{"line":82,"column":23}}})) != null ? stack1 : "")
    + "                <img\n                    src=\"https://cdn-do.sfactor.com.ua/welcome-cdn/mediafiles/tilda/2021/project3795420_1615477180/images/tild3866-6261-4162-a235-393933306166__main-bg.png\"\n                    alt=\"\"\n                    class=\"header-img\"\n                >\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});