var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            Дякуємо!\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            Вибачте!\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "            Ми з вами зв'яжемося найближчим часом!\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "            Щось пішло не так. Спробуйте, будь ласка, пізніше.\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "        <button id=\"close-btn\" class=\"btn btn-success text-white btn-fs rounded-pill fw-bold mb-lg-2 py-lg-2 px-lg-4\">\n            Дякую\n        </button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "        <button id=\"close-btn\" class=\"btn btn-secondary text-white btn-fs rounded-pill fw-bold mb-lg-2 py-lg-2 px-lg-4\">\n            Закрити\n        </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"pop-up-window\" class=\"pop-up-window bg-dark col-11 col-sm-8 mx-auto px-0 text-white d-flex flex-column align-items-center\n            pt-3 pb-4 text-center px-3 pt-lg-4\">\n    <svg\n            id=\"close-icon\"\n            class=\"close-icon position-absolute cursor-pointer\"\n            width=\"23px\"\n            height=\"23px\"\n            viewBox=\"0 0 23 23\"\n            version=\"1.1\"\n            xmlns=\"http://www.w3.org/2000/svg\"\n            xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n        <g stroke=\"none\" stroke-width=\"1\" fill=\"#fff\" fill-rule=\"evenodd\">\n            <rect transform=\"translate(11.313708, 11.313708) rotate(-45.000000) translate(-11.313708, -11.313708) \" x=\"10.3137085\" y=\"-3.6862915\" width=\"2\" height=\"30\"></rect>\n            <rect transform=\"translate(11.313708, 11.313708) rotate(-315.000000) translate(-11.313708, -11.313708) \" x=\"10.3137085\" y=\"-3.6862915\" width=\"2\" height=\"30\"></rect>\n        </g>\n    </svg>\n\n    <header class=\"fs-3 fs-lg-3 fw-bold\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"requestIsSuccessful") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":23,"column":15}}})) != null ? stack1 : "")
    + "    </header>\n\n    <p class=\"fs-7 mt-2 mb-4 mt-lg-3 pb-lg-2 fs-lg-7 text-lightgray\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"requestIsSuccessful") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":31,"column":15}}})) != null ? stack1 : "")
    + "    </p>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"requestIsSuccessful") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":34,"column":4},"end":{"line":42,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});