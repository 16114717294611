import { sendHttpRequest } from "./send-http-request";

const analyticsUrl = '';
const userId = new URL(window.location.href).searchParams.get('c');

function createMetricsObj(metricKey: string): object {
    return {
        user_data: userId,
        metrics: {
            [metricKey]: Date().toString(),
        },
    }
}

export function sendWasOnPageMoreThan15Sec(): void {
    const time = 15 * 1000;
    const body = createMetricsObj('moreThan15Seconds');
    const timeout = setTimeout(() => {
        sendHttpRequest('POST', analyticsUrl, body)
            .then(() => clearTimeout(timeout))
            .catch((error) => console.error(error));
    }, time);
}

export function sendCalculatorWasTouched(): void {
    const body = createMetricsObj('playWithCalculator');
    sendHttpRequest('POST', analyticsUrl, body)
        .then(() => {})
        .catch((error) => console.error(error));
}

export function sendScrollToSubmitButton(): void {
    const body = createMetricsObj('scrollToCallMeButton');
    sendHttpRequest('POST', analyticsUrl, body)
        .then(() => {})
        .catch((error) => console.error(error));
}

export function sendSubmitButtonWasClicked(): void {
    const body = createMetricsObj('callMeButtonClick');
    sendHttpRequest('POST', analyticsUrl, body)
        .then(() => {})
        .catch((error) => console.error(error));
}

export function sendPersonalAccWasClicked(): void {
    const body = createMetricsObj('personalAccountRedirect');
    sendHttpRequest('POST', analyticsUrl, body)
        .then(() => {})
        .catch((error) => console.error(error));
    window.location.href = 'https://my.sfactor.com.ua/';
}

export function sendScrollToCalculator(): void {
    const body = createMetricsObj('scrollToCalculator');
    sendHttpRequest('POST', analyticsUrl, body)
        .then(() => {})
        .catch((error) => console.error(error));
}