let textBlock: HTMLElement = document.querySelector('#animation-text');

function textBlockIsInViewport(): Boolean {
    const textBlockTop: number = textBlock.getBoundingClientRect().top;
    const textBlockMarginTop = window.getComputedStyle(textBlock, null).getPropertyValue('margin-top');
    const textBlockMarginTopValue= parseInt(textBlockMarginTop);
    return (window.innerHeight > (textBlockTop + textBlockMarginTopValue));
}

export function showTextBlock(): void {
    !textBlock ? textBlock = document.querySelector('#animation-text') : null;
    const classToShow = 'visible';
    const delay = 500;

    if (textBlockIsInViewport()) {
        setTimeout(() => {
            textBlock ? textBlock.classList.add(classToShow) : null;
            window.removeEventListener('scroll', showTextBlock);
            textBlock = null;
        }, delay);
    }
}

window.addEventListener('scroll', showTextBlock);