// @ts-ignore
import headerTemplate from "../../partials/header.handlebars";
// @ts-ignore
import mainTemplate from "../../partials/main/index.handlebars";
// @ts-ignore
import footerTemplate from "../../partials/footer/index.handlebars";
import { TypeInterface } from "../../interfaces/TypeInterface";

const header: Element = document.querySelector('#header');
const main: Element = document.querySelector('#main');
const footer: Element = document.querySelector('#footer');

export const templatesData: TypeInterface = {
    header: {
        template: headerTemplate,
        dataForTemplate: {},
        parentElement: header,
    },
    main: {
        template: mainTemplate,
        dataForTemplate: {},
        parentElement: main,
    },
    footer: {
        template: footerTemplate,
        dataForTemplate: {},
        parentElement: footer,
    }
};
