export function sendHttpRequest(method: string, url: string, body: any, csrftoken: string = ''): Promise<void> {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(method, url);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("X-CSRFToken", csrftoken);
        xhr.send(JSON.stringify(body));
        xhr.onload = function() {
            console.log(xhr.response);
            if (xhr.status !== 200) {
                reject(new Error('Щось пішло не так. Спробуйте пізніше.'));
            }
            resolve();
        };
        xhr.onerror = function() {
            reject(new Error('Щось пішло не так. Спробуйте пізніше.'));
        }
    });
}