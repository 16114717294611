export function createBodyForRequest(action: string = 'slackCallMe', value: string, isNotOrder: boolean = true): object {
    const simplePayload = { userId: new URL(window.location.href).searchParams.get('c') };
    const complexPayload = {
        userId: new URL(window.location.href).searchParams.get('c'),
        value,
    };

    const objToSend = {
        action,
        payload: complexPayload,
    };

    if (isNotOrder) {
        // @ts-ignore
        objToSend.payload = simplePayload;
    }

    return objToSend;
}