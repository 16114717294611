let formLoader: HTMLElement = document.querySelector('#form-loader');

export function showFormLoader(btnSelector: string) {
    if (btnSelector) {
        document.querySelector(btnSelector).classList.add('invisible');
    }
    formLoader = formLoader ?? document.querySelector('#form-loader');
    formLoader.classList.remove('d-none');
}

export function hideLoader(btnSelector: string) {
    formLoader = formLoader ?? document.querySelector('#form-loader');
    formLoader.classList.add('d-none');
    if (btnSelector) {
        document.querySelector(btnSelector).classList.remove('invisible');
    }
}