import { checkInputValue } from "./utils/check-input-value";
import { hideLoader, showFormLoader } from "./utils/form-loader";
import { getCsrftoken } from "./utils/get-csrftoken";
import { sendHttpRequest } from "./utils/send-http-request";
import { showPopUpWindow } from "./pop-up-window";
import { createBodyForRequest } from "./utils/create-body-for-request";
import { disableFormSubmission, formCanSend } from "./utils/disable-form-submission";
import {
    sendCalculatorWasTouched,
    sendPersonalAccWasClicked, sendScrollToCalculator, sendScrollToSubmitButton,
    sendSubmitButtonWasClicked,
    sendWasOnPageMoreThan15Sec
} from "./utils/metrics";
import {isInViewport} from "./utils/is-in-viewport";

export function loadComplexCalculator(): void {
    const complexCalculator: HTMLElement = document.querySelector('#complex-calculator');
    const inputField: HTMLInputElement = complexCalculator.querySelector('#input-field');
    const gracePeriodButtons: NodeList = complexCalculator.querySelectorAll('.grace-period-btn');
    const overpaymentWrapper: HTMLElement = complexCalculator.querySelector('#overpayment-wrapper');
    const gracePeriodWrapper: HTMLElement = complexCalculator.querySelector('#grace-period-wrapper');
    const nextMonthsWrapper: HTMLElement = complexCalculator.querySelector('#next-months-wrapper');
    const askButton: HTMLElement = complexCalculator.querySelector('#ask-button');
    const orderButton: HTMLElement = complexCalculator.querySelector('#order-button');
    const personalAccountButton: HTMLElement = document.querySelector('#personal-account-button');

    let currentGracePeriod: number = 1;
    let oneTimeCommissionPercentage: number = 0.05; // 5%
    let desiredAmount: number = 0;

    const numberOfPeriods: number = 12;
    const monthlyCommissionPercentage: number = 0.05; // 5%
    const loanRatePercentage: number = 0.0001; // 0.01%

    const maxValueOfCalculator: number = 35000;

    function setPlaceholder(value: number): void {
        inputField.setAttribute('placeholder', `${value}`);
    }

    function makeGracePeriodButtonsInactive(): void {
        const className = 'btn-success';
        gracePeriodButtons.forEach((item: HTMLElement) => {
            item.classList.remove(className);
        });
    }

    function setGracePeriod(e: any): any {
        if (e.target.dataset.gracePeriod === currentGracePeriod) {
            return;
        }

        currentGracePeriod = e.target.dataset.gracePeriod;
        const className = 'btn-success';
        makeGracePeriodButtonsInactive();
        e.target.classList.add(className);
    }

    function imitateInput() {
        inputField.dispatchEvent(new Event('input'));
    }

    function calculateGracePeriod(S: number): number {
        const R = loanRatePercentage,
            N = numberOfPeriods;

        return S * R / N * Math.pow((1 + R / N), N) / (Math.pow((1 + R / N), N) - 1);
    }

    function calculateNextMonths(S: number): number {
        const R = loanRatePercentage,
            N = numberOfPeriods,
            Km = monthlyCommissionPercentage;

        return S * R / N * Math.pow((1 + R / N), N) / (Math.pow((1 + R / N), N) - 1) + Km * S;
    }

    function calculateOverpayment(S: number, Shand: number): number {
        const Pml = calculateGracePeriod(S),
            N = numberOfPeriods,
            Km = monthlyCommissionPercentage,
            L = currentGracePeriod;

        return (Pml * N + Km * S * (N - L) - Shand) / 365;
    }

    function calculateCredit(e: any): void {
        e.target.value = e.target.value.replace(/[^\d]/g, '');
        e.target.value = checkInputValue(e.target.value, maxValueOfCalculator);
        let initialSum = +inputField.getAttribute('placeholder');
        desiredAmount = (e.target.value) ? e.target.value : initialSum;
        const S = desiredAmount * (1 + oneTimeCommissionPercentage);

        gracePeriodWrapper.textContent = calculateGracePeriod(S).toFixed(2);
        nextMonthsWrapper.textContent = calculateNextMonths(S).toFixed(2);
        overpaymentWrapper.textContent = calculateOverpayment(S, desiredAmount).toFixed(2);
    }

    function submit(body: object): void {
        if (formCanSend) {
            disableFormSubmission();
            showFormLoader('.buttons-wrapper');

            const csrftoken = getCsrftoken();

            sendHttpRequest('POST', 'https://welcome.sfactor.com.ua/api/button-actions/', body, csrftoken)
                .then(() => {
                    hideLoader('.buttons-wrapper');
                    showPopUpWindow({ requestIsSuccessful: true });
                })
                .catch((error) => {
                    hideLoader('.buttons-wrapper');
                    showPopUpWindow({ requestIsSuccessful: false });
                    console.error(error);
                });
        } else {
            showPopUpWindow({ requestIsSuccessful: true });
        }
    }

    function onAskButtonClick(e: any): void {
        e.preventDefault();

        const objToSend = createBodyForRequest('ask', `${desiredAmount}`);
        submit(objToSend);
    }

    function onOrderButtonClick(e: any): void {
        e.preventDefault();

        const objToSend = createBodyForRequest('ask', `${desiredAmount}`, false);
        submit(objToSend);
    }

    setPlaceholder(maxValueOfCalculator);
    inputField.addEventListener('input', calculateCredit);
    imitateInput();
    gracePeriodButtons.forEach(button => {
       button.addEventListener('click', (e) => {
           setGracePeriod(e);
           imitateInput();
       });
    });
    askButton.addEventListener('click', onAskButtonClick);
    orderButton.addEventListener('click', onOrderButtonClick);

    // analytics
    function checkIfCalculatorWasTouched() {
        sendCalculatorWasTouched();
        inputField.removeEventListener('focus', checkIfCalculatorWasTouched);
    }

    function checkIfSubmitButtonWasClicked() {
        sendSubmitButtonWasClicked();
        askButton.removeEventListener('click', checkIfSubmitButtonWasClicked);
    }

    function checkIfPersonalAccButtonWasClicked() {
        sendPersonalAccWasClicked();
        personalAccountButton.removeEventListener('click', checkIfPersonalAccButtonWasClicked);
    }

    function checkIfCalculatorIsInViewport() {
        if (isInViewport(complexCalculator)) {
            sendScrollToCalculator();
            window.removeEventListener('scroll', checkIfCalculatorIsInViewport);
        }
    }

    inputField.addEventListener('focus', checkIfCalculatorWasTouched);
    askButton.addEventListener('click', checkIfSubmitButtonWasClicked);
    personalAccountButton.addEventListener('click', checkIfPersonalAccButtonWasClicked);
    window.addEventListener('scroll', checkIfCalculatorIsInViewport);
    sendWasOnPageMoreThan15Sec();
}