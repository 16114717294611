// @ts-ignore
import popUpWindowTemplate from '../partials/common/pop-up-window.handlebars';

let backdrop: HTMLElement = document.querySelector('#backdrop');
let popUpWindow: HTMLElement = document.querySelector('#pop-up-window');
const classToShowPopUp = 'is-visible';
const animationDelay = 150;

function showBackdrop(dataForTemplate: PopUpWindowDataInterface) {
    backdrop.innerHTML = popUpWindowTemplate(dataForTemplate);
    backdrop.classList.add('d-flex');
    const timeout = setTimeout(() => {
        backdrop.classList.add(classToShowPopUp);
        clearTimeout(timeout);
    }, animationDelay);
}

function hideBackdrop() {
    backdrop.classList.remove(classToShowPopUp);
    const timeout = setTimeout(() => {
        backdrop.classList.remove('d-flex');
        clearTimeout(timeout);
    }, animationDelay);
}

export function showPopUpWindow(dataForTemplate: PopUpWindowDataInterface) {
    showBackdrop(dataForTemplate);

    popUpWindow = document.querySelector('#pop-up-window');
    const closeBtn: HTMLElement = popUpWindow.querySelector('#close-btn');
    closeBtn.addEventListener('click', closePopUpWindow);
    const timeout = setTimeout(() => {
        popUpWindow.classList.add(classToShowPopUp);
        console.log(popUpWindow);
        clearTimeout(timeout);
    }, animationDelay);
}

function closePopUpWindow() {
    console.log(popUpWindow);
    popUpWindow = document.querySelector('#pop-up-window');
    popUpWindow.classList.remove(classToShowPopUp);

    const timeout = setTimeout(() => {
        hideBackdrop();
        clearTimeout(timeout);
    }, animationDelay);
}

function onBackdropClick(e: any) {
    console.log('YES');
    if (e.target.getAttribute('id') === 'backdrop' || e.target.getAttribute('id') === 'close-icon'
        || e.target.closest('#close-icon')) {
        closePopUpWindow();
    }
}

window.addEventListener('load', () => {
    backdrop = document.querySelector('#backdrop');
    backdrop.addEventListener('click', onBackdropClick);
});