var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./simple-calculator.handlebars"),depth0,{"name":"simple-calculator","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./complex-calculator.handlebars"),depth0,{"name":"complex-calculator","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "            <img\n                src=\"https://cdn-do.sfactor.com.ua/welcome-cdn/mediafiles/tilda/2021/project3795420_1615477180/images/tild3735-3037-4830-a564-666263376636__rozetka-paperplane.png\"\n                alt=\"\"\n                class=\"paper-plane position-absolute paper-plane-simple\"\n                id=\"paper-plane\"\n            >\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "            <img\n                src=\"https://cdn-do.sfactor.com.ua/welcome-cdn/mediafiles/tilda/2021/project3795420_1615477180/images/tild3735-3037-4830-a564-666263376636__rozetka-paperplane.png\"\n                alt=\"\"\n                class=\"paper-plane position-absolute paper-plane-complex\"\n                id=\"paper-plane\"\n            >\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                    «Сучасний Факторинг» — простий крок до мрії!\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                    Більше десяти років «Сучасний факторинг» надає зручні та вигідні послуги з кредитування\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                            КОМІСІЇ ЗА ДОСТРОКОВЕ ПОГАШЕННЯ НЕ ІСНУЄ\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                            ВІДСУТНЯ КОМІСІЯ ЗА ДОСТРОКОВЕ ПОГАШЕННЯ\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section\n    class=\"container main mt-5 d-flex flex-column flex-xl-row pb-5 col-lg-8 col-xl-12 px-xl-0 px-xxl-2 flex-wrap\"\n>\n    <div\n        id=\"backdrop\"\n        class=\"backdrop pop-up-window-wrapper align-items-center justify-content-center position-fixed\">\n    </div>\n\n    <article id=\"calculator\" class=\"col col-12 col-xl-6 px-0 pe-xl-3 pe-xxl-5\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"simpleCalculator") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "    </article>\n\n    <article id=\"test\" class=\"col col-12 col-xl-6 px-0 mt-5 ps-xl-3 ps-xxl-5 d-flex align-items-xl-center position-relative\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"simpleCalculator") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":32,"column":15}}})) != null ? stack1 : "")
    + "        <section class=\"text-white fs-7 fs-md-6\">\n            <header class=\"text-center mb-3\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"simpleCalculator") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":39,"column":23}}})) != null ? stack1 : "")
    + "            </header>\n\n            <article class=\"credit-info row text-center g-0\">\n                <div\n                    class=\"col-6 px-0 border-bottom border-end border-success d-flex justify-content-center\n                    align-items-center\"\n                >\n                    <p class=\"col-8 px-0 mb-0\">\n                        ПОГАСИТИ МОЖНА ДОСТРОКОВО\n                    </p>\n                </div>\n                <div\n                    class=\"col-6 px-0 border-bottom border-success d-flex justify-content-center align-items-center\"\n                >\n                    <p class=\"col-8 px-0 mb-0\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"simpleCalculator") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":55,"column":24},"end":{"line":59,"column":31}}})) != null ? stack1 : "")
    + "                    </p>\n                </div>\n                <div class=\"col-6 px-0 border-end border-success d-flex justify-content-center align-items-center\">\n                    <p class=\"col-10 px-0 mb-0\">\n                        ЗРУЧНИЙ РОЗРАХУНОК ЩОМІСЯЧНОГО ПЛАТЕЖУ ПО КРЕДИТУ\n                    </p>\n                </div>\n                <div class=\"col-6 px-0 d-flex align-items-center justify-content-center\">\n                    <p class=\"col-11 px-0 mb-0\">\n                        МАКСИМАЛЬНИЙ ТЕРМІН КРЕДИТУ 24 МІСЯЦІ\n                    </p>\n                </div>\n            </article>\n        </section>\n    </article>\n\n    <article id=\"animation-text\" class=\"animation-text col-12 fw-bold text-center fs-4 fs-sm-4 fs-lg-3 fs-xl-3 mt-5\">\n        <p class=\"text-primary mb-0\">\n            ВЕСНА - САМЕ ЧАС ДЛЯ ПОЗИТИВНИХ ЗМІН\n        </p>\n        <p class=\"text-success\">\n            ЗАМОВЛЯЙТЕ ГРОШІ НА КАРТКУ! БЕЗЛІЧ ТОВАРІВ ЧЕКАЮТЬ НА ВАС!\n        </p>\n    </article>\n</section>\n\n";
},"usePartial":true,"useData":true});