import './scss/style.scss';
import createAndInsertHTMLBlock from './ts/auxiliary/create-html-block';
import { templatesData } from './ts/auxiliary/templates-data';
import { loadSimpleCalculator } from "./ts/simple-calculator";
import { loadComplexCalculator } from "./ts/complex-calculator";
import { loadBalloonAnimation } from "./ts/balloon-animation";
import { loadPaperPlaneAnimation } from "./ts/paper-plane-animation";

import './ts/pop-up-window';
import './ts/balloon-animation';
import './ts/text-animation';

require('./images/logo.png');
require('./images/logo-dark.png');

const testObj = {
    simpleCalculator: false,
    fullName: 'Олексій Олексійович',
    text: `Саме для вас діють унікальні умови на отримання кредиту готівкою терміном на 12 місяців.
          Ви можете обрати свій тарифний план без сплати комісії один чи два місяці`,
    sum: '15000.00',
    text2: `Скористайтеся унікальною можливістю отримати готівку на картку без зайвих переплат!`,
    images: [
        { link: 'https://cdn-do.sfactor.com.ua/welcome-cdn/mediafiles/tilda/2021/project3795420_1615477180/images/tild3533-6363-4039-a331-663566663965__visa_inc_logosvg.png' },
        { link: 'https://cdn-do.sfactor.com.ua/welcome-cdn/mediafiles/tilda/2021/project3795420_1615477180/images/tild3939-3061-4163-a632-623563666437__1280px-mastercard_20.png' },
        { link: 'https://cdn-do.sfactor.com.ua/welcome-cdn/mediafiles/tilda/2021/project3795420_1615477180/images/tild3762-3030-4733-b932-353365373233__logo_easypay--dark.png' },
        { link: 'https://cdn-do.sfactor.com.ua/welcome-cdn/mediafiles/tilda/2021/project3795420_1615477180/images/tild3235-6362-4439-b331-393533373330__platon-logo.png' },
        { link: 'https://cdn-do.sfactor.com.ua/welcome-cdn/mediafiles/tilda/2021/project3795420_1615477180/images/tild3836-6531-4466-b535-346565613464__1024px-google_pay_gp.png' },
        { link: 'https://cdn-do.sfactor.com.ua/welcome-cdn/mediafiles/tilda/2021/project3795420_1615477180/images/tild3236-3161-4433-b732-336264306237__1024px-apple_pay_log.png' },
        { link: 'https://cdn-do.sfactor.com.ua/welcome-cdn/mediafiles/tilda/2021/project3795420_1615477180/images/tild6534-6563-4838-b834-653630393262__logo.svg' },
    ],
};

const testObj2 = {
    simpleCalculator: true,
    fullName: 'Олексій',
    text: `Не відкладай плани! Купуйте всі потрібні товари вже зараз. Ми збільшили ваш кредитний ліміт.`,
    sum: '15000.00',
    text2: `Замовляй гроші на картку за вигідними умовами!`,
};

const testObj3 = {
    simpleCalculator: true,
    fullName: 'Олексій Олексійович',
    text: `Не відкладай плани! Купуйте всі потрібні товари вже зараз. Ми збільшили ваш кредитний ліміт.`,
    sum: '15000.00',
    text2: `Замовляйте гроші на картку за вигідними умовами!`,
};

function buildHTMLDocument(dataForTemplate: object): void {
    Object.values(templatesData).forEach((item) => {
        item.dataForTemplate = dataForTemplate;
        createAndInsertHTMLBlock(item);
    });
    // @ts-ignore
    if (dataForTemplate.simpleCalculator) {
        loadSimpleCalculator();
    } else {
        loadComplexCalculator();
    }
    loadBalloonAnimation();
    loadPaperPlaneAnimation();
}

buildHTMLDocument(testObj2);

let counter = 1;

document.querySelector('#change-content-btn').addEventListener('click', () => {
    if (counter === 1) {
        buildHTMLDocument(testObj);
        counter++;
    } else if (counter === 2) {
        buildHTMLDocument(testObj2);
        counter++;
    } else {
        buildHTMLDocument(testObj3);
        counter = 1;
    }
});

/*const plane: HTMLElement = document.querySelector('#paper-plane');
let lastScrollTop = 0;
let x = 0;
let y = 0;
let deg = 0;*/
/*
window.addEventListener('scroll', () => {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop){
        console.log('down');
        x -= 2;
        y -= 2;
        deg -= 3;
        if (x < -112) {
            x += 2;
            y += 2;
        }
        plane.style.transform = `translate(${x}px,${y}px) rotate(${deg}deg)`
    } else {
        console.log('up');
    }
    lastScrollTop = st <= 0 ? 0 : st;
});*/
